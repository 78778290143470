import React, { useState, useEffect, useCallback, useRef } from 'react';
import { NavLink, Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars, faTachometerAlt, faHome, faUsers, faSun, faMoon, faChevronDown, faChevronUp,
    faSignOutAlt, faBox, faCog, faPlane, faList, faBoxOpen,
    faBuilding, faUserTag, faHandshake, faUserTie, faUser,
    faMapMarkerAlt, faClock, faTruck, faUserCircle, faGlobe,
    faChartLine, faCalendar, faEnvelope, faTasks, faTimes, faChevronRight,
    faTicketAlt, faPlus, faBus, faStore, faFont, faRoute, faBook, faMoneyBillWave,
    faChartBar, faCashRegister, faMapMarkedAlt, faConciergebell, faBlog, faImages
} from '@fortawesome/free-solid-svg-icons';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Loader from "../components/Loader";
import Swal from "sweetalert2";
import Logo from "../assets/images/logo.png";

const MainLayout = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [rol, setRol] = useState('');
    const [avatar, setAvatar] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const contentRef = useRef(null);
    const sidebarRef = useRef(null);
    const URLZFILES = process.env.REACT_APP_API_ZFILES;
    const URLWEB = process.env.REACT_APP_WEB;

    const toggleDarkMode = useCallback(() => setDarkMode(prev => !prev), []);
    const toggleSidebar = useCallback(() => {
        setSidebarOpen(prev => !prev);
    }, []);
    const toggleSubmenu = useCallback((index) => {
        setActiveSubmenu(prevActiveSubmenu => {
            const newActiveSubmenu = prevActiveSubmenu === index ? null : index;
            localStorage.setItem('activeSubmenu', newActiveSubmenu);
            return newActiveSubmenu;
        });
    }, []);
    const toggleProfileMenu = useCallback(() => setProfileMenuOpen(prev => !prev), []);

    const closeSidebar = useCallback(() => {
        if (window.innerWidth < 992) {
            setSidebarOpen(false);
        }
    }, []);

    useEffect(() => {
        //let user = JSON.parse(localStorage.getItem('dataUser'));
        //setName(user.name);
        //setLastName(user.last_name);
        //setRol(user.rol.name);
        //setAvatar(user.avatar);
        console.log('MainLayout.js');
    }, []);

    useEffect(() => {
        const storedActiveSubmenu = localStorage.getItem('activeSubmenu');
        if (storedActiveSubmenu) {
            setActiveSubmenu(parseInt(storedActiveSubmenu));
        }

        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setSidebarOpen(true);
            }
        };

        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && window.innerWidth < 992) {
                closeSidebar();
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeSidebar]);

    useEffect(() => {
        document.body.classList.toggle('dark-mode', darkMode);
    }, [darkMode]);

    const closeSession = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¿Deseas cerrar la sesión?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                    localStorage.removeItem('dataUser');
                    navigate('/');
                }, 1000);
            }
        });
    }

    const menuItems = [
        { icon: faTachometerAlt, text: 'Dashboard', link: '/dashboard' },
        {
            icon: faTachometerAlt,
            text: 'Cuentas',
            submenu: [
                { icon: faList, text: 'Listado de Cuentas', link: '/accounts' },
            ],
        },
        {
            icon: faTachometerAlt,
            text: 'Configuración',
            submenu: [
                { icon: faList, text: 'Gestión de Formularios', link: '/configforms' },
            ],
        },
        /*{
            icon: faCog,
            text: 'Administración',
            submenu: [
                { icon: faBuilding, text: 'Empresa', link: '/empresa' },
                { icon: faStore, text: 'Sucursales', link: '/sucursales' }, // Changed from faBuilding to faStore
                { icon: faUserTag, text: 'Roles', link: '/roles' },
                { icon: faHandshake, text: 'Socios', link: '/socios' },
                { icon: faUserTie, text: 'Conductores', link: '/conductores' },
                { icon: faUser, text: 'Usuarios', link: '/usuarios' },
                { icon: faMapMarkerAlt, text: 'Destinos', link: '/destinos' },
                { icon: faClock, text: 'Horarios', link: '/horarios' },
                { icon: faTruck, text: 'Tipos de Unidades', link: '/tipos-unidades' }, // Changed from faTruck to faBus
                { icon: faBus, text: 'Unidades', link: '/unidades' }, // Changed from faTruck to faBus
                { icon: faFont, text: 'Letras', link: '/letras' }, // Changed from faTruck to faFont
                { icon: faRoute, text: 'Viajes', link: '/viajes' }, // Changed from faTruck to faRoute
                { icon: faBook, text: 'Bitacora', link: '/bitacora' }, // Changed from faTruck to faBook
                { icon: faMoneyBillWave, text: 'Precios', link: '/precios' }, // Changed from faTruck to faMoneyBillWave
            ],
        },
        {
            icon: faChartBar, // Changed from faBox to faChartBar for 'Reportes'
            text: 'Reportes',
            submenu: [
                { icon: faCashRegister, text: 'Corte de Caja', link: '/corte-caja' }, // Changed from faBoxOpen to faCashRegister
                { icon: faChartLine, text: 'Reporte de Ganancias', link: '/reportes-ganancias' }, // Changed from faList to faChartLine
            ],
        },
        {
            icon: faGlobe,
            text: 'Páginas Web',
            submenu: [
                { icon: faHome, text: 'Home', link: '/home' }, // Changed from faCalendar to faHome
                { icon: faUsers, text: 'Nosotros', link: '/nosotros' }, // Changed from faEnvelope to faUsers
                { icon: faMapMarkedAlt, text: 'Destinos', link: '/destinos' }, // Changed from faTasks to faMapMarkedAlt
                { icon: faPlane, text: 'Servicios', link: '/servicios' }, // Changed from faTasks to faConciergebell
                { icon: faBlog, text: 'Blog', link: '/blog' }, // Changed from faTasks to faBlog
                { icon: faEnvelope, text: 'Contacto', link: '/contacto' }, // Changed from faTasks to faEnvelope
                { icon: faImages, text: 'Galeria', link: '/galeria' }, // Changed from faTasks to faImages
            ],
        }*/
    ];

    const MenuItem = ({ item, index }) => {
        const isActive = location.pathname === item.link || (item.submenu && item.submenu.some(subItem => location.pathname === subItem.link));
        const isSubmenuOpen = activeSubmenu === index;

        const handleItemClick = () => {
            if (item.submenu) {
                toggleSubmenu(index);
            } else {
                navigate(item.link);
                closeSidebar();
            }
        };

        return (
            <div className={`sidebar-menu-item ${isActive ? 'active' : ''}`}>
                <button
                    onClick={handleItemClick}
                    className={`sidebar-menu-button ${isActive ? 'active' : ''}`}
                    aria-expanded={isSubmenuOpen}
                >
                    <span className="sidebar-menu-icon">
                        <FontAwesomeIcon icon={item.icon} />
                    </span>
                    <span className="sidebar-menu-text">{item.text}</span>
                    {item.submenu && (
                        <FontAwesomeIcon
                            icon={isSubmenuOpen ? faChevronUp : faChevronDown}
                            className="sidebar-submenu-icon"
                        />
                    )}
                </button>
                {item.submenu && isSubmenuOpen && (
                    <div className="sidebar-submenu">
                        {item.submenu.map((subItem, subIndex) => (
                            <NavLink
                                key={subIndex}
                                to={subItem.link}
                                className={({ isActive }) => `sidebar-submenu-item ${isActive ? 'active' : ''}`}
                                onClick={closeSidebar}
                            >
                                <FontAwesomeIcon icon={subItem.icon} className="sidebar-submenu-icon" />
                                <span className="sidebar-submenu-text">{subItem.text}</span>
                            </NavLink>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
            {loading && <Loader />}
            <nav ref={sidebarRef} className={`sidebar ${sidebarOpen ? 'open' : 'collapsed'}`}>
                <div className="sidebar-header">
                    <img src={Logo} alt="Logo" className="sidebar-logo" />
                    <span className="sidebar-title">AdminLeads</span>
                    <button className="sidebar-close" onClick={closeSidebar} aria-label="Close sidebar">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="sidebar-menu">
                    {menuItems.map((item, index) => (
                        <React.Fragment key={index}>
                            <MenuItem item={item} index={index} />
                            {index < menuItems.length - 1 && <div className="sidebar-menu-separator" />}
                        </React.Fragment>
                    ))}
                </div>
            </nav>

            <div className={`main-content ${sidebarOpen ? 'sidebar-open' : 'sidebar-collapsed'}`}>
                <header className="top-bar">
                    <button onClick={toggleSidebar} className="menu-toggle" aria-label="Toggle sidebar">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <div className="top-bar-right">
                        <button onClick={toggleDarkMode} className="dark-mode-toggle" aria-label="Toggle dark mode">
                            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                        </button>
                        <div className="profile-menu">
                            <button onClick={toggleProfileMenu} className="profile-toggle" aria-haspopup="true" aria-expanded={profileMenuOpen}>
                                <img src={Logo} alt="Avatar" className="profile-avatar" />
                                <span className="profile-name">Administrador</span>
                                <FontAwesomeIcon icon={faChevronDown} className="profile-icon" />
                            </button>
                            {profileMenuOpen && (
                                <div className="profile-dropdown">
                                    <div className="profile-header">
                                        <p className="profile-fullname">Administrador</p>
                                        {/*<p className="profile-email">[ {rol} ]</p>*/}
                                    </div>
                                    {/*<Link to="/perfil" className="profile-link"><FontAwesomeIcon icon={faUserCircle} /> Mi Perfil</Link>
                                    <Link to={URLWEB} className="profile-link"><FontAwesomeIcon icon={faGlobe} /> Ir a la web</Link>
                                    <div className="profile-divider"></div>*/}
                                    <a style={{cursor: "pointer"}} onClick={closeSession} className="profile-link"><FontAwesomeIcon icon={faSignOutAlt} /> Cerrar Sesión</a>
                                </div>
                            )}
                        </div>
                    </div>
                </header>

                <main className="content" ref={contentRef}>
                    <Outlet />
                </main>

                <footer className="footer">
                    <div className="container-fluid">
                        <span className="footer-text">© Copyright 2024. Todos los derechos reservados.</span>
                        <span className="footer-version">Versión 2.1.0</span>
                    </div>
                </footer>

            </div>
        </div>
    );
}

export default MainLayout;