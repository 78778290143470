import React from 'react';

const Loader = () => {
  return (
    <div>
        <div class="lds-ripple-container loader">
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
            <h3 class="lds-ripple-message"></h3>
        </div>
    </div>
  )
}

export default Loader;
