import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ContentHeader = ({ title }) => {
    const navigate = useNavigate();

    return (
        <nav aria-label="breadcrumb" className="modern-breadcrumb" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard"> <i className="fas fa-home me-1"></i> Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{title}</li>
            </ol>
            <button onClick={() => navigate(-1)} className="btn-back">
                <FontAwesomeIcon icon={faChevronLeft} /> Atrás
            </button>
        </nav>
    );
}

export default ContentHeader;
