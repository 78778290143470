import React from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function AuthFacebook({ setUser, setAccessToken }) {
  const navigate = useNavigate();
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;

  const handleLogin = () => {
    window.FB.login(function(response) {
      if (response.authResponse) {
        setAccessToken(response.authResponse.accessToken);
        window.FB.api('/me', { fields: 'id,name,email,picture' }, (userData) => {
          setUser(userData);
          saveUserToBackend(userData, response.authResponse.accessToken, response.authResponse.expiresIn);
          navigate('/dashboard');
        });
      } else {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El usuario canceló el inicio de sesión o no autorizó completamente.'
        }); 
      }
    }, { scope: 'email,pages_show_list,leads_retrieval' });
  };

  const saveUserToBackend = async (userData, accessToken, expiresIn) => {
    try {
      const form = new FormData();
      form.append('facebook_id', userData.id);
      form.append('facebook_name', userData.name);
      form.append('facebook_email', userData.email);
      form.append('facebook_picture_url', userData.picture.data.url);
      form.append('facebook_is_silhouette', (userData.picture.data.is_silhouette) ? 1 : 0);
      form.append('tokenString', '');
      form.append('tokenExpires', expiresIn);
      form.append('longLivedAccessToken', accessToken);
      const response = await axios.post(ENDPOINT + 'save-user', form, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (response.status !== 200) throw new Error('Failed to save user data');
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  return (
    <button 
        onClick={handleLogin}
        type="button" 
        className="btn btn-primary btn-lg w-100">
        <i className="fab fa-facebook-f me-2"></i>
        Acceder con Facebook
    </button>
  );
}

export default AuthFacebook;