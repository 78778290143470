import React, { useState, useEffect } from 'react';
import ContentHeader from '../../components/ContentHeader';
import Loader from "../../components/Loader";
import Switch from "react-switch";
import Swal from "sweetalert2";
import axios from "axios";
import $, { get } from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'select2';
import 'select2/dist/css/select2.min.css';
import { Tooltip } from 'bootstrap';

const Accounts = () => {
    const [title, setTitle] = useState("Agregar Cuenta");
    const [loading, setLoading] = useState(false);
    const [account_id, setAccountId] = useState(0);
    const [page_id, setPageId] = useState(0);
    const [account_name, setAccountName] = useState('');
    const [origin_id_om, setOriginIdOm] = useState(0);
    const [group_id_om, setGroupIdOm] = useState(0);
    const [data, setData] = useState([]);
    const [pages, setPages] = useState([]);
    const [active, setActive] = useState(0);
    const [inactive, setInactive] = useState(0);
    const [status_filter, setStatusFilter] = useState(2);
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

    useEffect(() => {
        getAll();
        getPages();
        setTimeout(() => {
            $('.select2').select2({ width: "100%", placeholder: "Seleccione" });
        }, 100);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);

    const selectStatus = [
        { label: 'Todos', value: 2 },
        { label: 'Activo', value: 1 },
        { label: 'Inactivo', value: 0 }
    ];

    $('#status_filter').on("change", function (e) {
        setStatusFilter(e.target.value);
    });

    $('#page_id').on("change", function (e) {
        setPageId(e.target.value);
    });

    const getPages = async () => {
        const response = await axios.get(ENDPOINT + 'get-all-pages-account');
        const pagesArray = response.data.data.map(function (element) {
            return {
                label: element.page_name,
                value: element.id,
            };
        })
        setPages(pagesArray);
    };

    const getAll = async () => {
        setLoading(true);
        var table = $('.main').DataTable();
        table.destroy();
        await axios.get(ENDPOINT + "get-all-accounts/" + status_filter).then((response) => {
            setData(response.data.data);
            setActive(response.data.data.filter((e) => e.status === 1).length);
            setInactive(response.data.data.filter((e) => e.status === 0).length);
            setTimeout(() => {
                $('.main').DataTable({
                    searching: true,
                    destroy: true,
                    retrieve: true,
                    pageLength: 10,
                    responsive: true,
                    autoWidth: true,
                    paging: true,
                    ordering: true,
                    sortable: true,
                    processing: false,
                    serverSide: false,
                    bInfo: true,
                    language: {
                        "decimal": "",
                        "emptyTable": "",
                        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros",
                        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                        "infoPostFix": "",
                        "thousands": ",",
                        "lengthMenu": "Mostrar _MENU_ registros",
                        "loadingRecords": "Cargando...",
                        "processing": "Procesando...",
                        "search": "Buscar:",
                        "paginate": {
                            "first": "<i class='fa-solid fa-circle-chevron-left'></i>",
                            "last": "<i class='fa-solid fa-circle-chevron-right'></i>",
                            "next": "<i class='fas fa-arrow-circle-right'></i>",
                            "previous": "<i class='fas fa-arrow-circle-left'></i>"
                        },
                        "zeroRecords": "No hay registros",
                        "searchPlaceholder": "Buscar...",
                    },
                });
                setLoading(false);
            }, 100);
        })
        .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    };

    const handleNew = () => {
        clearField();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = new FormData();
        form.append("user_id", 1);
        form.append("page_id", page_id);
        form.append("account_name", account_name);
        form.append("origin_id_om", origin_id_om);
        form.append("group_id_om", group_id_om);
        if (account_id === 0) {
            await axios
                .post(ENDPOINT + "save-account", form)
                .then((response) => {
                    getAll();
                    clearField();
                    setLoading(false);
                    $("#closemodal").trigger("click");
                    Swal.fire({
                        icon: "success",
                        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                        showConfirmButton: false,
                        timer: 3000,
                        html: '<div class="text-custom-alert">' + response.data.msg + "</div>",
                        showClass: { popup: "animate__animated animate__fadeInDown" },
                        hideClass: { popup: "animate__animated animate__fadeOutUp" },
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                        showConfirmButton: true,
                        timer: 3000,
                        html: '<div class="text-custom-alert">' + error.response.data.msg + "</div>",
                        showClass: { popup: "animate__animated animate__fadeInDown" },
                        hideClass: { popup: "animate__animated animate__fadeOutUp" },
                    });
                });
        } else {
            setLoading(true);
            await axios
                .put(ENDPOINT + "update-account/" + account_id, {
                    page_id: page_id,
                    account_name: account_name,
                    origin_id_om: origin_id_om,
                    group_id_om: group_id_om,
                })
                .then((response) => {
                    getAll();
                    clearField();
                    setLoading(false);
                    $("#closemodal").trigger("click");
                    Swal.fire({
                        icon: "success",
                        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                        showConfirmButton: false,
                        timer: 3000,
                        html: '<div class="text-custom-alert">' + response.data.msg + "</div>",
                        showClass: { popup: "animate__animated animate__fadeInDown" },
                        hideClass: { popup: "animate__animated animate__fadeOutUp" },
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    setLoading(true);
                    Swal.fire({
                        icon: "error",
                        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                        showConfirmButton: true,
                        timer: 3000,
                        html: '<div class="text-custom-alert">' + error.response.data.msg + "</div>",
                        showClass: { popup: "animate__animated animate__fadeInDown" },
                        hideClass: { popup: "animate__animated animate__fadeOutUp" },
                    });
                });
        }
    };

    const clearField = () => {
        setAccountId(0);
        setPageId(0);
        setAccountName('');
        setOriginIdOm(0);
        setGroupIdOm(0);
        setTitle("Agregar Cuenta");
    };

    const toggleSwitch = async (val) => {
        setLoading(true);
        await axios
            .get(ENDPOINT + "get-account-status/" + val)
            .then((response) => {
                getAll();
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                    showConfirmButton: false,
                    timer: 3000,
                    html: '<div class="text-custom-alert">' + response.data.msg + "</div>",
                    showClass: { popup: "animate__animated animate__fadeInDown" },
                    hideClass: { popup: "animate__animated animate__fadeOutUp" },
                });
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                    showConfirmButton: true,
                    timer: 3000,
                    html: '<div class="text-custom-alert">' + error.response.data.msg + "</div>",
                    showClass: { popup: "animate__animated animate__fadeInDown" },
                    hideClass: { popup: "animate__animated animate__fadeOutUp" },
                });
            });
    };

    const handleEdit = async (id) => {
        setLoading(true);
        await axios
            .get(ENDPOINT + "get-account/" + id)
            .then((response) => {
                setLoading(false);
                setAccountId(response.data.data.account_id);
                setPageId(response.data.data.page_id);
                setAccountName(response.data.data.account_name);
                setOriginIdOm(response.data.data.origin_id_om);
                setGroupIdOm(response.data.data.group_id_om);
                setTitle("Editar Cuenta");
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    };

    const handleDelete = (id, name) => {
        Swal.fire({
            title: `¿Desea eliminar el registro [ ${name} ] ?`,
            text: "No podrá recuperarlo",
            icon: "warning",
            confirmButtonText: "Sí, eliminarlo",
            confirmButtonColor: "#469408",
            showCancelButton: true,
            cancelButtonColor: "#c71c22",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios
                    .delete(ENDPOINT + "delete-account/" + id).then((response) => {
                        getAll();
                        setLoading(false);
                        Swal.fire({
                            icon: "success",
                            title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                            showConfirmButton: false,
                            timer: 3000,
                            html: '<div class="text-custom-alert">' + response.data.msg + "</div>",
                            showClass: { popup: "animate__animated animate__fadeInDown" },
                            hideClass: { popup: "animate__animated animate__fadeOutUp" },
                        });
                    })
                    .catch(function (error) {
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
                            showConfirmButton: true,
                            timer: 3000,
                            html: '<div class="text-custom-alert">' + error.response.data.msg + "</div>",
                            showClass: { popup: "animate__animated animate__fadeInDown" },
                            hideClass: { popup: "animate__animated animate__fadeOutUp" },
                        });
                    });
            }
        });
    };

    const resetPage = () => {
        window.location.reload();
    };

    return (
        <>
            {loading && <Loader />}
            <div className="modal fade" id="modal-default" aria-labelledby="modal-default" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h4 className="modal-title">{title}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="formulario" onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="name" className="col-form-label col-form-label-sm">
                                                Cuenta
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control form-control-sm`}
                                                placeholder="Ingrese cuenta"
                                                id="account_name"
                                                name="account_name"
                                                value={account_name}
                                                onChange={(e) => setAccountName(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="page_id" className="col-form-label col-form-label-sm">
                                                Página
                                            </label>
                                            <select
                                                className="form-control form-control-sm"
                                                id="page_id"
                                                name="page_id"
                                                required
                                            >
                                                <option value="">Seleccione</option>
                                                {pages.map((item, index) => (
                                                    <option
                                                        selected={item.value ===  page_id ? "selected" : ""}
                                                        key={index}
                                                        value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="origin_id_om" className="col-form-label col-form-label-sm">
                                                Origen
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control form-control-sm`}
                                                placeholder="Ingrese Origen"
                                                id="origin_id_om"
                                                name="origin_id_om"
                                                value={origin_id_om}
                                                onChange={(e) => setOriginIdOm(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="group_id_om" className="col-form-label col-form-label-sm">
                                                Grupo
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control form-control-sm`}
                                                placeholder="Ingrese grupo"
                                                id="group_id_om"
                                                name="group_id_om"
                                                value={group_id_om}
                                                onChange={(e) => setGroupIdOm(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-success">
                                    <i className="fa-regular fa-floppy-disk me-1"></i>
                                    {account_id === 0 ? "Guardar" : "Actualizar"}
                                </button>
                                <button
                                    id="closemodal"
                                    type="button"
                                    className="btn btn-danger"
                                    data-bs-dismiss="modal"
                                >
                                    <i className="fa-solid fa-ban me-1"></i> Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <ContentHeader title="Módulo de Cuentas" />
                <div className="card">
                    <div className="card-header">
                        <div className="indications-header row">
                            <div className="col-md-7">
                                <h4 className="card-title m-0 page-title">
                                    Listado de Cuentas
                                </h4>
                            </div>
                            <div className="col-md-5" style={{ textAlign: "right" }}>
                                <button
                                    onClick={handleNew}
                                    data-bs-toggle="modal"
                                    data-bs-target="#modal-default"
                                    className="btn btn-success btn-sm me-1"
                                >
                                    <i className="fas fa-plus me-1"></i> Agregar
                                </button>
                                <button
                                    onClick={resetPage}
                                    className="btn btn-info btn-sm me-1"
                                    type="button">
                                    <i className="fa fa-refresh me-1"></i> Refrescar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="indications row mb-4">
                            <div className="col-md-4 col-sm-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="select_status" className="col-form-label col-form-label-sm">
                                        Estado
                                    </label>
                                    <select
                                        className="form-control form-control-sm select2"
                                        id="status_filter"
                                        name="status_filter"
                                    >
                                        {selectStatus.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3" style={{ display: "flex", justifyContent: 'start', alignItems: "center" }}>
                                <button
                                    onClick={() => getAll()}
                                    className="btn btn-primary btn-sm me-1 mt-3"
                                    type="button" >
                                    <i className="fa fa-search me-1"></i> Buscar
                                </button>
                            </div>
                            <div className="col-xs-2 col-md-4">
                                <div className="form-group">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="text-center">
                                                <td className="indications-table" width="30%" >Total</td>
                                                <td className="indications-table "width="30%" >Activos</td>
                                                <td className="indications-table "width="30%" >Inactivos</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td id="TuserT">{active + inactive}</td>
                                                <td id="TuserA">{active}</td>
                                                <td id="TuserI">{inactive}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <table className="table table-hover main">
                            <thead>
                                <tr>
                                    <th width="10%" className="text-center">#</th>
                                    <th width="20%" className="text-left">Cuenta</th>
                                    <th width="20%" className="text-center">Página</th>
                                    <th width="10%" className="text-center">Origen</th>
                                    <th width="10%" className="text-center">Grupo</th>
                                    <th width="10%" className="text-center">Estado</th>
                                    <th width="10%" className="text-center">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="center">
                                            <kbd># {item.account_id}</kbd>
                                        </td>
                                        <td className="center">
                                            {item.account_name}
                                        </td>
                                        <td className="text-center">
                                            {item.page.page_name}
                                        </td>
                                        <td className="text-center">
                                            {item.origin_id_om}
                                        </td>
                                        <td className="text-center">
                                            {item.group_id_om}
                                        </td>
                                        <td className="text-center">
                                            <div data-bs-toggle="tooltip" data-bs-placement="top" title="Estado">
                                                <Switch
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    checked={item.status === 1 ? true : false}
                                                    onChange={() => toggleSwitch(item.account_id)}
                                                />
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div>
                                                <span
                                                    title="Editar registro"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top">
                                                    <button
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modal-default"
                                                        className="btn-sm btn-warning me-1"
                                                        onClick={() => handleEdit(item.account_id)}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                </span>
                                                <button
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title="Eliminar registro"
                                                    className="btn-sm btn-danger me-1"
                                                    onClick={() => handleDelete(item.account_id, item.account_name)}>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Accounts;