import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import axios from 'axios';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState('');
  const [count_users, setCountUsers] = useState(0);
  const [count_drivers, setCountDrivers] = useState(0);
  const [count_partners, setCountPartners] = useState(0);
  const [count_packages, setCountPackages] = useState(0);
  const [count_tickets, setCountTickets] = useState(0);
  const [count_units, setCountUnits] = useState(0);
  const [total_ticket, setTotalTicket] = useState(0);
  const [total_package, setTotalPackage] = useState(0);
  const [data_tickets, setDataTickets] = useState([]);
  const [data_packages, setDataPackages] = useState([]);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;

  useEffect(() => {
    const current = new Date();
    const year = current.getFullYear()
    setYear(year);
  }, []);

  const getCountDashboard = async () => {
    setLoading(true);
    await axios.get(ENDPOINT + 'get-count-dashboard')
      .then(response => {
        setCountUsers(response.data.data.countUsers);
        setCountDrivers(response.data.data.countDrivers);
        setCountPartners(response.data.data.countPartners);
        setCountPackages(response.data.data.countPackages);
        setLoading(false);
      }).catch(function (error) {
        console.log(error)
      });
  };

  return (
    <>
      {loading && <Loader />}
      <section className="mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-6">
              <div className="small-box gradient-style3 text-white box-shadow">
                <div className="inner">
                  <h3>1</h3>
                  <p>Cuentas<br/>Registradas</p>
                </div>
                <div className="icon">
                  <i className="fas fa-users" aria-hidden="true"></i>
                </div>
                <Link to="/" className="small-box-footer">
                  Más información <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-6">
              <div className="small-box gradient-style4 text-white box-shadow">
                <div className="inner">
                  <h3>1</h3>
                  <p>Páginas<br/>Registradas</p>
                </div>
                <div className="icon">
                  <i className="fa-solid fa-user-group" aria-hidden="true"></i>
                </div>
                <Link to="/" className="small-box-footer">
                  Más información <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-6">
              <div className="small-box gradient-style5 text-white box-shadow">
                <div className="inner">
                  <h3>1</h3>
                  <p>Usuarios<br/>Registrados</p>
                </div>
                <div className="icon">
                  <i className="fa-solid fa-users-gear" aria-hidden="true"></i>
                </div>
                <Link to="/" className="small-box-footer">
                  Más información <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>
            {/*<div className="col-lg-3 col-6">
              <div className="small-box gradient-style8 text-white box-shadow">
                <div className="inner">
                  <h3>{total_package}</h3>
                  <p>Leads</p>
                </div>
                <div className="icon">
                  <i className="fas fa-dollar-sign" aria-hidden="true"></i>
                </div>
                <Link to="/" className="small-box-footer">
                  Más información <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>*/}
          </div>

        </div>
      </section>
    </>
  )
}

export default Dashboard;