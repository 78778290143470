import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from "../../components/Loader";
import Logo from '../../assets/images/logo.png';
import Image from "../../assets/images/image-app-leads-facebook.png";
import AuthFacebook from './AuthFacebook';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showpass, setShowPass] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;
  const CLIENTID = process.env.REACT_APP_CLIENTID;
  const CLIENTSECRET = process.env.REACT_APP_CLIENTSECRET;

  useEffect(() => {
    const storedToken = localStorage.getItem('fbAccessToken');
    if (storedToken) {
      setAccessToken(storedToken);
      initializeFacebookSDK(storedToken);
    } else {
      initializeFacebookSDK();
    }
  }, []);

  const showPassword = () => {
    setShowPass(!showpass);
  }

  const handleSubmit = async e => {
      e.preventDefault();
      setLoading(true);
      const form = new FormData();
      form.append('email', email);
      form.append('password', password);
      await axios.post(ENDPOINT + 'login', form)
        .then(response => {
        setLoading(false);
        localStorage.setItem('fbAccessToken', response.data.data.longLivedAccessToken);
        Swal.fire({
            icon: 'success',
            title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
            showConfirmButton: false,
            html: '<div class="text-custom-alert">'+response.data.msg+'</div>',
            showClass: { popup: 'animate__animated animate__fadeInDown' },
            hideClass: { popup: 'animate__animated animate__fadeOutUp' }
        });
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 2000);
      }).catch(function (error) {
        console.log(error);
        setLoading(false);
        Swal.fire({
            icon: 'error',
            title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
            showConfirmButton: true,
            html: '<div class="text-custom-alert">'+error.response.data.msg+'</div>',
            showClass: { popup: 'animate__animated animate__fadeInDown' },
            hideClass: { popup: 'animate__animated animate__fadeOutUp' }
        });
      });
  }; 

  const initializeFacebookSDK = (token = null) => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: CLIENTID,
        cookie: true,
        xfbml: true,
        version: 'v12.0'
      });
      if (token) {
        fetchUserData(token);
      } else {
        window.FB.getLoginStatus(function(response) {
          if (response.status === 'connected') {
            const shortLivedToken = response.authResponse.accessToken;
            exchangeToken(shortLivedToken);
          }
        });
      }
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };

  const exchangeToken = (shortLivedToken) => {
    window.FB.api(
      '/oauth/access_token',
      'GET',
      {
        grant_type: 'fb_exchange_token',
        client_id: CLIENTID,
        client_secret: CLIENTSECRET,
        fb_exchange_token: shortLivedToken
      },
      function(response) {
        if (response && !response.error) {
          const longLivedToken = response.access_token;
          const expiresIn = response.expires_in;
          setAccessToken(longLivedToken);
          localStorage.setItem('fbAccessToken', longLivedToken);
          fetchUserData(longLivedToken, shortLivedToken, expiresIn);
        }
      }
    );
  };

  const fetchUserData = (token) => {
    window.FB.api('/me', { fields: 'id,name,email,picture.type(large)', access_token: token }, (response) => {
      console.log('user', response)
      setUser(response);
    });
  };

  return (
    <> 
      <div className="login-page d-flex align-items-center justify-content-center" style={{ height: "100vh", padding: "20px" }}>
        {loading && <Loader />}
        <form onSubmit={handleSubmit}>
          <div className="container" style={{ maxWidth: "1800px" }}>
            <div className="row justify-content-md-center">
              <div className="col-xl-12 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5" style={{ display: "flex", borderRadius: "15px !important", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center" style={{ background: "#0f1d3a", zIndex: 99 }}>
                        <img style={{ width: "95%", marginBottom: "20px" }} className="img-responsive" src={Image} />
                      </div>
                      <div className="col-lg-6" style={{ zIndex: 99, background: "#fcfefd" }}>
                        <div className="row">
                          <div className="col-md-12 col-md-offset-1 mt-4 text-center">
                            <img className="img-responsive" height={120} width={135} src={Logo} style={{ marginBottom: "1em" }} />
                          </div>
                        </div>
                        <div className="col-md-12 col-md-offset-1 mt-4">
                          <div className="form-input mb-4">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="far fa-user-circle"></i>
                              </span>
                            </div>
                            <input
                              id="email"
                              name="email"
                              type="email"
                              placeholder="Ingrese su email"
                              onChange={e => setEmail(e.target.value)} 
                              value={email}
                              className={`form-control form-control-sm`}
                              style={{paddingLeft: "10%"}}
                            />
                          </div>
                          <div className="form-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="fas fa-lock"></i></span>
                            </div>
                            <input
                              id="password"
                              name="password"
                              type={!showpass ? 'password' : 'text'}
                              placeholder="Ingrese su contraseña"
                              onChange={e => setPassword(e.target.value)} 
                              value={password}
                              className={`form-control form-control-sm`}
                              style={{paddingLeft: "10%"}}
                            />
                            <a 
                              onClick={showPassword}
                              style={{position:"absolute",margin:0,right:"20px",marginTop:"-21px",zIndex:"999", backgroundColor: "transparent !important", border: "none !important", cursor: "pointer"}}  
                              className="">
                              <i style={{color:"#033c73"}} className={`fas fa-eye ${!showpass ? '' : 'd-none'} `}></i>
                              <i style={{color:"#033c73"}} className={`fa-solid fa-eye-slash ${!showpass ? 'd-none' : ''} `}></i>
                            </a>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="checkbox">
                                  <div className="checkbox">
                                    <label className="remember">
                                      <input 
                                        className="me-2"
                                        type="checkbox" 
                                        name="remember" 
                                        value="1" 
                                        id="remember"
                                      />
                                      Recuérdame                       
                                    </label>
                                  </div>
                                </div>
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right", marginTop: "20px", marginBottom: "25px" }}>
                              <button 
                                type="submit" 
                                className="btn btn-success btn-lg text-white">
                                  <i className="fas fa-sign-in-alt me-2"></i>
                                  Iniciar Sesión
                              </button>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col text-center">
                              <p> - O - </p>
                              <AuthFacebook setUser={setUser} setAccessToken={setAccessToken} />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col text-center">
                              <p className="copyright">
                                Copyright 2024. Todos los derechos reservados.
                                <br/>Versión 2.1.0
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;