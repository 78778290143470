import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import MainLayout from './components/MainLayout';
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Accounts from './pages/accounts/Accounts';
import ConfigForms from './pages/configforms/ConfigForms';


export default function App() {    
    return (
        <div>
           
           <Router>   
                <Routes>
                    <Route path="/" element={<AuthLayout />}>
                        <Route path='/' element={<Login />} />
                    </Route>
                    <Route path="/" element={<MainLayout />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/accounts" element={<Accounts />} />
                        <Route path="/configforms" element={<ConfigForms />} />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
}